
import { breakpoints } from '../variables.yml';

import 'focus-visible';
// import './vendor/lib.js';
// import { whatever } from './vendor/lib.js';
import { getWindowWidth } from './helpers.js';

export * from './CookieConsentManager.js';
// export * from './vue/app.js';

( () => {
	// Account menu
	// const toggles = document.querySelectorAll( '.js-nav' );
	// if ( !toggles ) {
	// 	return;
	// }
	window.addEventListener( 'click', ( e ) => {
		if ( e.target.classList.contains( 'js-nav' ) ) {
			document.body.classList.toggle( 'nav-open', !document.body.classList.contains( 'nav-open' ) );
		}
	} );
	window.addEventListener( 'resize', () => {
		if ( getWindowWidth() > breakpoints.f.width ) {
			document.body.classList.toggle( 'nav-open', document.body.classList.contains( 'nav-open' ) );
		}
	} );
} )();

// ( () => {
// 	// Dynamic amount field and Gift Aid calculation
// 	const amount = document.getElementById( 'amount' );
// 	const donation_amount_receptacle = document.getElementById( 'js-donation-amount' );
// 	const gift_aid_amount_receptacle = document.getElementById( 'js-gift-aid-amount' );
// 	const amount_populators = document.querySelectorAll( '.form__amount-button' );

// 	if ( !amount ) {
// 		return;
// 	}

// 	const url_search_params = new URLSearchParams( window.location.search );
// 	const preexisting_amount = url_search_params.get( 'amount' );
// 	if ( !preexisting_amount || preexisting_amount <= 0  ) {
// 		highlightDefaultAmount();
// 	}

// 	function normaliseAmount( amount ) {
// 		amount = parseFloat( amount );
// 		if ( isNaN( amount ) || amount < 0 ) {
// 			return 0;
// 		}
// 		return amount;
// 	}

// 	function updateGiftAidNumbers() {
// 		if ( !donation_amount_receptacle || !gift_aid_amount_receptacle ) {
// 			return;
// 		}
// 		const original_amount = normaliseAmount( amount.value );
// 		const gift_aid_amount = original_amount * 0.25;
// 		donation_amount_receptacle.innerHTML = ' £' + original_amount.toFixed( 2 ) + ' ';
// 		gift_aid_amount_receptacle.innerHTML = ' £' + gift_aid_amount.toFixed( 2 ) + ' (25%) ';
// 	}

// 	function highlightPopulators() {
// 		const current_amount = normaliseAmount( amount.value );
// 		for ( let i = 0; i < amount_populators.length; i++ ) {
// 			const value = normaliseAmount(
// 				amount_populators[i].getAttribute( 'data-populate-amount' )
// 			);
// 			if ( value === current_amount ) {
// 				amount_populators[i].classList.add( 'form__amount-button--selected' );
// 				continue;
// 			}
// 			amount_populators[i].classList.remove( 'form__amount-button--selected' );
// 		}
// 	}

// 	function highlightDefaultAmount() {
// 		amount.value = normaliseAmount(
// 			amount_populators[0].getAttribute( 'data-populate-amount' )
// 		);
// 		updateAmountDescription( amount_populators[0] );

// 		const donation_form = document.getElementById( 'js-payment' );
// 		const frequency_elements = donation_form.querySelectorAll( 'input[name="frequency"]' );
// 		let frequency = getFrequency();
// 		frequency_elements.forEach( ( element ) => {
// 			element.addEventListener( 'click', ( e ) => {
// 				frequency = e.target.value;
// 				for ( let i = 0; i < amount_populators.length; i++ ) {
// 					if ( frequency === amount_populators[i].dataset.amountFrequency && parseInt( amount_populators[i].id ) === 0  ) {
// 						const value = normaliseAmount(
// 							amount_populators[i].getAttribute( 'data-populate-amount' )
// 						).toFixed( 2 );
// 						amount.value = value;
// 						highlightPopulators();
// 						updateGiftAidNumbers();
// 					}
// 				}
// 			} );
// 		} );
// 	}

// 	amount.addEventListener( 'change', () => {
// 		highlightPopulators();
// 		updateGiftAidNumbers();
// 	} );

// 	for ( let i = 0; i < amount_populators.length; i++ ) {
// 		amount_populators[i].addEventListener( 'click', ( ( populator ) => {
// 			const value = normaliseAmount(
// 				populator.getAttribute( 'data-populate-amount' )
// 			).toFixed( 2 );
// 			return () => {
// 				amount.value = value;
// 				highlightPopulators();
// 				updateGiftAidNumbers();
// 			};
// 		} )( amount_populators[i] ) );
// 	}

// 	highlightPopulators();
// 	updateGiftAidNumbers();


// 	function getFrequency() {
// 		const frequency_elements = document.querySelectorAll( 'input[name="frequency"]' );
// 		let frequency = frequency_elements[0].value;
// 		frequency_elements.forEach( ( element ) => {
// 			if ( element.checked ) {
// 				frequency = element.value;
// 			}
// 		} );
// 		return frequency;
// 	}

// 	// function showErrorMessage() {
// 	// 	if ( !donation_widget_amount_description ) {
// 	// 		return;
// 	// 	}
// 	// 	donation_widget_amount_description.innerText = 'Please enter a valid amount.';
// 	// 	donation_widget_amount_description.classList.add( 'donation-widget__amount-description--error-message' );
// 	// }

// } )();

function doToggle( toggler ) {
	const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
	let revert_values = false;

	if ( toggler.getAttribute( 'data-revert-values' ) && toggler.getAttribute( 'data-revert-values' ) === 'true' ) {
		revert_values = true;
	}

	for ( let i = 0; i < togglees.length; i += 1 ) {
		togglees[i].classList.toggle( 'toggled' );

		if ( revert_values ) {
			const inputs_to_revert = togglees[i].querySelectorAll( '[data-original-value]' );

			for ( let x = 0; x < inputs_to_revert.length; x += 1 ) {
				inputs_to_revert[x].value = inputs_to_revert[x].getAttribute( 'data-original-value' );
			}

			const checkables_to_revert = togglees[i].querySelectorAll( '[data-originally-checked]' );

			for ( let y = 0; y < checkables_to_revert.length; y += 1 ) {
				checkables_to_revert[y].checked = checkables_to_revert[y].getAttribute( 'data-originally-checked' );
			}
		}
	}
	toggler.classList.toggle( 'toggler--toggled' );
}

( () => {
	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		event.preventDefault();
		doToggle( event.target );
	} );

	window.addEventListener( 'change', ( event ) => {
		if ( !event.target.classList.contains( 'change-toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	const form = document.querySelector( '[data-id="{{ form.anchor }}"]' );
	if ( form 	) {
		form.addEventListener( 'freeform-stripe-appearance', ( event ) => {
			event.elementOptions.appearance = Object.assign(
				event.elementOptions.appearance,
				{
					variables: {
						colorPrimary: '#0d6efd',
						fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
						fontSizeBase: '16px',
						spacingUnit: '0.2em',
						tabSpacing: '10px',
						gridColumnSpacing: '20px',
						gridRowSpacing: '20px',
						colorText: '#212529',
						colorBackground: '#ffffff',
						colorDanger: '#dc3545',
						borderRadius: '5px',
					},
					rules: {
						'.Tab, .Input': {
							border: '1px solid #cbced0',
							boxShadow: 'none',
						},
						'.Tab:focus, .Input:focus': {
							border: '1px solid #0b5ed7',
							boxShadow: 'none',
							outline: '0',
							transition: 'border-color .15s ease-in-out',
						},
						'.Label': {
							fontSize: '16px',
							fontWeight: '400',
						},
					},
				}
			);
		} );
	}
} )();

( () => {
	const fields = [
		{ element: 'address-search', field: '', mode: pca.fieldMode.SEARCH }, // eslint-disable-line no-undef
		{ element: 'address-line1', field: 'Line1', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'address-line2', field: 'Line2', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'address-town', field: 'City', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'address-postcode', field: 'PostalCode', mode: pca.fieldMode.POPULATE }, // eslint-disable-line no-undef
		{ element: 'address-country', field: 'CountryName', mode: pca.fieldMode.COUNTRY } // eslint-disable-line no-undef
	];

	const loqate_control = new pca.Address( fields, { key: window.loqateKey } ); // eslint-disable-line no-undef, no-unused-vars

	loqate_control.listen( 'populate', () => {
		const input = document.querySelector( '#' + fields[ 0 ].element );
		if ( input.hasAttribute( 'data-toggler' ) ) {
			doToggle( document.querySelector( input.getAttribute( 'data-toggler' ) ) );
		}
	} );
} )();

( () => {

	// Video posters

	const videos = document.querySelectorAll( '.js-video' );

	if ( !videos.length > 0 ) {
		return;
	}

	function buildVideoPlayer( container ) {
		const video_poster = container.querySelector( '.video__poster-wrapper' );
		const video_embed = container.querySelector( '.video__embed' );

		if ( !video_poster || !video_embed ) {
			return;
		}

		const video_iframe = video_embed.querySelector( 'iframe' );

		if ( !video_iframe ) {
			return;
		}

		video_poster.classList.add( 'video__poster-wrapper--hidden' );
		video_embed.classList.remove( 'video__embed--hidden' );
		let src = video_iframe.getAttribute( 'src' );
		src = src.replace( 'autoplay=0', 'autoplay=1' );
		video_iframe.setAttribute( 'src', src );
	}

	function handleVideoClick( container ) {
		return ( event ) => {
			if ( event.preventDefault ) {
				event.preventDefault();
			} else {
				event.returnValue = false;
			}

			buildVideoPlayer( container );
		};
	}

	for ( let i = 0; i < videos.length; i++ ) {
		videos[i].addEventListener( 'click', handleVideoClick( videos[i] ) );
	}

} )();

( () => {
	// Dynamic amount field
	const payment_amounts = document.querySelectorAll( '.js__payment-amount' );

	payment_amounts.forEach( ( payment_amount ) => {
		const amount_populators = document.querySelectorAll( '.form__amount-button[data-amount-frequency=' + payment_amount.getAttribute( 'data-frequency' ) + ']' );

		payment_amount.addEventListener( 'change', ( e ) => {
			highlightPopulators( payment_amount, amount_populators );
			payment_amount.value = normaliseAmount( e.target.value ).toFixed( 2 );
		} );

		amount_populators.forEach( ( amount_populator ) => {
			amount_populator.addEventListener( 'click', ( ( ) => {
				const value = normaliseAmount(
					amount_populator.getAttribute( 'data-populate-amount' )
				).toFixed( 2 );
				payment_amount.value = value;
				highlightPopulators( payment_amount, amount_populators );
			} ) );
		} );

		highlightPopulators( payment_amount, amount_populators );
	} );

	function normaliseAmount( amount ) {
		amount = parseFloat( amount );
		if ( isNaN( amount ) || amount < 0 ) {
			return 0;
		}
		return amount;
	}

	function highlightPopulators( current_amount_input, amount_populators ) {
		const current_amount = normaliseAmount( current_amount_input.value );
		const form_amount_actual = current_amount_input.closest( '.form__amount-actual' );
		let amount_option_selected = false;
		for ( let i = 0; i < amount_populators.length; i++ ) {
			const value = normaliseAmount(
				amount_populators[ i ].getAttribute( 'data-populate-amount' )
			);
			if ( value === current_amount ) {
				amount_populators[ i ].classList.add( 'form__amount-button--selected' );
				amount_option_selected = true;
				continue;
			}
			amount_populators[ i ].classList.remove( 'form__amount-button--selected' );
		}

		if ( !amount_option_selected ) {
			form_amount_actual.classList.add( 'form__amount-actual--selected' );
		} else {
			form_amount_actual.classList.remove( 'form__amount-actual--selected' );
		}
	}
} )();
